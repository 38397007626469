<script>
  export let currentYear = new Date().getFullYear().toString().substr(-2);
</script>

<main>
  <table width="75%" border="0" align="center" cellpadding="5" cellspacing="0">
    <tr>
      <td height="80px">&nbsp;</td>
    </tr>
    <tr>
      <td
        ><div align="center">
          <img
            src="images/FMG-properties.gif"
            alt="FMG Properties"
            width="220"
            height="200"
          />
        </div></td
      >
    </tr>
    <tr>
      <td height="40px">&nbsp;</td>
    </tr>
    <tr>
      <td
        ><div align="center" class="quote">
          <b>Renting properties in Dane County, Wisconsin.</b>
        </div></td
      >
    </tr>
    <tr>
      <td height="120px">&nbsp;</td>
    </tr>
    <tr>
      <td
        ><div align="center">
          <img
            src="images/equalhousing.gif"
            alt="Equal Housing Opportunity"
            height="60"
          />
        </div></td
      >
    </tr>
    <tr>
      <td height="10px">&nbsp;</td>
    </tr>
    <tr>
      <td
        ><div align="center" class="footer">
          Copyright &copy; 2002-{currentYear}, FMG Properties, LLC. All rights
          reserved.
        </div></td
      >
    </tr>
  </table>
</main>
